@font-face {
  font-family: "Nexa Bold";
  src: local("Nexa Bold"), url(./fonts/NexaBold/Nexa-Bold.woff) format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Nexa Regular";
  src: local("Nexa Regular"), url(./fonts/NexaBold/Nexa-Regular.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Nexa Light";
  src: local("Nexa Light"), url(./fonts/NexaBold/Nexa-Light.woff) format("woff");
  font-weight: 400;
}

* {
  font-family: "Nexa Regular";
}