@import 'antd/dist/antd.css';
@import "fonts.less";


.ant-select .ant-select-selector  {
  border-radius:10px !important;
}

.ant-input {
  border-radius: 10px;
}

.ant-input-password {
  border-radius: 10px;

}

.ant-input-wrapper {
  .ant-input {
    border-radius: 10px;
  }

  .ant-input-search-button {
    border-radius: 0 10px 10px 0 !important;

  }
}

.ant-picker {
  border-radius: 10px;
}