.OTPContainer > div {
    margin-right: 10px;
}

@media (max-width: 1199px) {
    .OTPContainer > div {
        margin-right: 16px !important;
    }
}

.OTPContainer > div:last-child {
    margin-right: 0;
}

.OTPCol {
    position: relative;
    justify-content: center;
    align-items: center;

    width: 50px !important;
    height: 50px !important;

    padding: 5px 0 0 !important;

    font-size: 20px !important;
    line-height: 100%;

    border: 1px solid #00000014;
    border-radius: 8px !important;
    box-shadow: 0px 0.5px 0px #0000000f;
}

@media (max-width: 1199px) {
    .OTPCol {
        width: 40px !important;
        height: 40px !important;
        font-size: 17px !important;
        border-radius: 5px !important;
    }
}
